<template>
	<div class="modal-body">
		<!-- CREAR -->
		<div v-if="propInfo.data.method == 'create'" class="row">
			<!-- ROL -->
			<div class="form-group col-md-12">
				<label>Rol</label>
				<v-select v-model="user.role_id" :options="requirements.roles" :reduce="rol => rol.id" label="name" placeholder="Seleccionar" />
			</div>
			<!-- EMPRESA -->
			<div class="form-group col-md-12" v-if="user.role_id == 3 || user.role_id == 4">
				<label for="">Empresa</label>
				<select @change="mtd_changeEnterprise" name="enterprise_id" class="form-select form-select-sm" v-model="user.enterprise_id" v-validate="'required'" data-vv-as="'Empresa'">
					<option :value="null">Seleccionar</option>
					<option :value="item.id" v-for="(item, index) in requirements.enterprises" :key="index">{{ item.denomination }}</option>
				</select>
				<span v-show="errors.has('enterprise_id')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('enterprise_id') }} </span>
			</div>
			<!-- SEDE -->
			<div class="form-group col-md-12" v-if="user.role_id == 4">
				<label for="">Sede</label>
				<select @change="mtd_changeCampu" name="campu_id" class="form-select form-select-sm" v-model="user.campu_id" v-validate="'required'" data-vv-as="'Sede'">
					<option :value="null">Seleccionar</option>
					<option :value="item.id" v-for="(item, index) in requirements.campus" :key="index">{{ item.name }}</option>
				</select>
				<span v-show="errors.has('campu_id')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('campu_id') }} </span>
			</div>
			<!-- CARGO -->
			<div class="form-group col-md-12" v-if="user.role_id == 4">
				<label for="">Cargo</label>
				<select name="job_position_id" class="form-select form-select-sm" v-model="user.job_position_id" v-validate="'required'" data-vv-as="'Cargo'">
					<option :value="null">Seleccionar</option>
					<option :value="item.id" v-for="(item, index) in requirements.job_positions" :key="index">{{ item.name }}</option>
				</select>
				<span v-show="errors.has('job_position_id')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('job_position_id') }} </span>
			</div>
			<!-- TIPO DE DOCUMENTO -->
			<div class="form-group col-md-6">
				<label for="">Tipo de Doc.</label>
				<v-select
					v-validate="'required'"
					data-vv-as="'Tipo de Doc.'"
					name="document_type_id"
					v-model="user.person.document_type_id"
					:options="requirements.document_types"
					:reduce="dt => dt.id"
					@option:selected="mtd_changeDocumentType"
					label="name"
					placeholder="Seleccionar" />
				<span v-show="errors.has('document_type_id')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('document_type_id') }} </span>
			</div>
			<!-- NUMERO DE DOCUMENTO -->
			<div v-if="document_type_selected != null && document_type_selected.code == '01'" class="form-group col-md-6">
				<label>Número de Doc.</label>
				<div class="input-group">
					<input
						v-validate="'required'"
						data-vv-as="'Número de Doc.'"
						name="document_number"
						v-model="filter.document_number"
						@change="mtd_changeFilterDocumentNumber"
						type="text"
						class="form-control form-control-sm" />
					<span v-show="errors.has('document_number')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('document_number') }} </span>

					<button @click="mtd_findPerson" class="btn btn-xs btn-primary"><i class="fas fa-search"></i> RENIEC</button>
				</div>
			</div>
			<div v-else class="form-group col-md-6">
				<label>Número de Doc.</label>
				<div class="input-group">
					<input
						v-validate="'required'"
						data-vv-as="'Número de Doc.'"
						name="document_number"
						v-model="user.person.document_number"
						@change="mtd_changeUserPersonDocumentNumber"
						type="text"
						class="form-control form-control-sm" />
					<span v-show="errors.has('document_number')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('document_number') }} </span>
				</div>
			</div>
			<!-- NOMBRES -->
			<div class="form-group col-md-12">
				<label>Nombres</label>
				<input v-validate="'required'" data-vv-as="'Nombres'" name="names" v-model="user.person.names" type="input" class="form-control text-uppercase form-control-sm" />
				<span v-show="errors.has('names')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('names') }} </span>
			</div>
			<!-- APELLIDO PATERNO -->
			<div class="form-group col-md-6">
				<label>Apellido paterno</label>
				<input
					v-validate="'required'"
					data-vv-as="'Apellido paterno'"
					name="father_last_name"
					v-model="user.person.father_last_name"
					type="text"
					class="form-control text-uppercase form-control-sm" />
				<span v-show="errors.has('father_last_name')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('father_last_name') }} </span>
			</div>
			<!-- APELLIDO MATERNO -->
			<div class="form-group col-md-6">
				<label>Apellido materno</label>
				<input
					v-validate="'required'"
					data-vv-as="'Apellido materno'"
					name="mother_last_name"
					v-model="user.person.mother_last_name"
					type="text"
					class="form-control text-uppercase form-control-sm" />
				<span v-show="errors.has('mother_last_name')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('mother_last_name') }} </span>
			</div>
			<!-- CORREO -->
			<div class="form-group col-md-12">
				<label>Correo</label>
				<input v-validate="'required|email'" data-vv-as="'Correo'" name="email" v-model="user.email" type="email" class="form-control form-control-sm" />
				<span v-show="errors.has('email')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('email') }} </span>
			</div>
			<!-- DIRECCION -->
			<div class="form-group col-md-12">
				<label>Dirección</label>
				<input v-validate="'required'" data-vv-as="'Dirección'" name="address" v-model="user.address" type="text" class="form-control text-uppercase form-control-sm" />
				<span v-show="errors.has('address')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('address') }} </span>
			</div>
			<!-- CELULAR -->
			<div class="form-group col-md-6">
				<label>Celular</label>
				<input v-validate="'required'" data-vv-as="'Celular'" name="cellphone" v-model="user.cellphone" type="text" class="form-control form-control-sm" />
				<span v-show="errors.has('cellphone')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('cellphone') }} </span>
			</div>
			<div class="form-group col-md-6">
				<label for="">Estado</label>
				<div class="form-check">
					<input v-model="user.enabled" class="form-check-input" type="checkbox" id="checkbox" />
					<label class="form-check-label" for="checkbox">Habilitado</label>
				</div>
			</div>
		</div>
		<!-- EDITAR -->
		<div v-else-if="propInfo.data.method == 'edit'" class="row">
			<!-- EMPRESA -->
			<div class="form-group col-md-12" v-if="user.role_id == 3 || user.role_id == 4 || user.role_id == 5">
				<label for="">Empresa</label>
				<select @change="mtd_changeEnterprise" name="enterprise_id" class="form-select form-select-sm" v-model="user.enterprise_id" v-validate="'required'" data-vv-as="'Empresa'">
					<option :value="null">Seleccionar</option>
					<option :value="item.id" v-for="(item, index) in requirements.enterprises" :key="index">{{ item.denomination }}</option>
				</select>
				<span v-show="errors.has('enterprise_id')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('enterprise_id') }} </span>
			</div>
			<!-- SEDE -->
			<div class="form-group col-md-12" v-if="user.role_id == 4 || user.role_id == 5">
				<label for="">Sede</label>
				<select @change="mtd_changeCampu" name="campu_id" class="form-select form-select-sm" v-model="user.campu_id" v-validate="'required'" data-vv-as="'Sede'">
					<option :value="null">Seleccionar</option>
					<option :value="item.id" v-for="(item, index) in requirements.campus" :key="index">{{ item.name }}</option>
				</select>
				<span v-show="errors.has('campu_id')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('campu_id') }} </span>
			</div>
			<!-- CARGO -->
			<div class="form-group col-md-12" v-if="user.role_id == 4">
				<label for="">Cargo</label>
				<select name="job_position_id" class="form-select form-select-sm" v-model="user.job_position_id" v-validate="'required'" data-vv-as="'Cargo'">
					<option :value="null">Seleccionar</option>
					<option :value="item.id" v-for="(item, index) in requirements.job_positions" :key="index">{{ item.name }}</option>
				</select>
				<span v-show="errors.has('job_position_id')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('job_position_id') }} </span>
			</div>

			<!-- NUMERO DE DNI. -->
			<div class="col-md-6">
				<label>Número de Doc.</label>
				<p>{{ user.person.document_number }}</p>
			</div>
			<!-- NOMBRES -->
			<div class="col-md-12">
				<label>Nombres</label>
				<p>{{ user.person.names }}</p>
			</div>
			<!-- APELLIDO PATERNO -->
			<div class="col-md-6">
				<label>Apellido paterno</label>
				<p>{{ user.person.father_last_name }}</p>
			</div>
			<!-- APELLIDO MATERNO -->
			<div class="col-md-6">
				<label>Apellido materno</label>
				<p>{{ user.person.mother_last_name }}</p>
			</div>
			<!-- CORREO -->
			<div class="form-group col-md-12">
				<label>Correo</label>
				<input v-validate="'required|email'" data-vv-as="'Correo'" name="email" v-model="user.email" type="email" class="form-control form-control-sm" />
				<span v-show="errors.has('email')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('email') }} </span>
			</div>
			<!-- DIRECCION -->
			<div class="form-group col-md-12">
				<label>Dirección</label>
				<input v-model="user.address" type="text" class="form-control form-control-sm text-uppercase" />
			</div>
			<!-- CELULAR -->
			<div class="form-group col-md-6">
				<label>Celular</label>
				<input v-model="user.cellphone" type="text" class="form-control form-control-sm" />
			</div>
			<div class="col-md-6">
				<label>Estado</label>
				<p>
					<span v-if="user.enabled == 1" class="badge badge-success">Habilitado</span>
					<span v-else-if="user.enabled == 0" class="badge badge-danger">Deshabilitado</span>
				</p>
			</div>
		</div>

		<div class="row mt-3">
			<div class="col-12 d-flex justify-content-end">
				<button @click="mtd_send" class="btn btn-primary mr-3">{{ propInfo.data.buttonSubmit }}</button>
				<button @click="mtd_close" class="btn btn-secondary">Cancelar</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	props: {
		propInfo: {
			required: true,
		},
		modal_id: {
			required: true,
		},
	},
	data() {
		return {
			requirements: {
				roles: [],
				document_types: [],
				enterprises: [],
				job_positions: [],
			},
			filter: {
				document_number: null,
			},
			user: {
				id: null,
				role_id: null,
				enterprise_id: null,
				job_position_id: null,
				campu_id: null,
				person: {
					id: null,
					document_type_id: null,
					document_number: null,
					names: null,
					father_last_name: null,
					mother_last_name: null,
				},
				address: null,
				cellphone: null,
				email: null,
				enabled: true,
			},
			document_type_selected: null,

			errorsBackend: {},
			methods: {
				init: {
					create: 'this.mtd_onCreate()',
					edit: 'this.mtd_onEdit()',
				},
				action: {
					create: 'this.mtd_store()',
					edit: 'this.mtd_update()',
				},
			},
		};
	},
	computed: {
		...mapGetters(['url_api']),
		cpd_btnDisabled() {
			return this.errors.any();
		},
	},
	mounted() {
		eval(this.methods.init[this.propInfo.data.method]);
	},
	methods: {
		...mapActions(['get', 'post', 'update']),
		mtd_onCreate() {
			this.mtd_createUser();
		},
		mtd_onEdit() {
			this.mtd_editUser();
		},
		mtd_createUser() {
			this.get({ url: `${this.url_api}/user/create` })
				.then(res => {
					if (res.state == 'success') {
						this.requirements.roles = res.roles;
						this.requirements.document_types = res.document_types;
						this.requirements.enterprises = res.enterprises;

						this.$emit('onload', { status: 'success' });
					}
				})
				.catch(err => console.log(err));
		},
		mtd_editUser() {
			this.get({ url: `${this.url_api}/user/${this.propInfo.data.row.id}/edit` })
				.then(res => {
					if (res.state == 'success') {
						this.requirements.enterprises = res.enterprises;
						this.mtd_setUser(res.user);
						this.$emit('onload', { status: 'success' });
					}
				})
				.catch(err => console.log(err));
		},
		mtd_setUser(user) {
			if (user.id != null) this.user.id = user.id;
			if (user.email != null) this.user.email = user.email;
			if (user.cellphone != null) this.user.cellphone = user.cellphone;
			if (user.address != null) this.user.address = user.address;
			if (user.enabled != null) this.user.enabled = user.enabled;
			//Person
			if (user.person.document_number != null) this.user.person.document_number = user.person.document_number;
			if (user.person.father_last_name != null) this.user.person.father_last_name = user.person.father_last_name;
			if (user.person.mother_last_name != null) this.user.person.mother_last_name = user.person.mother_last_name;
			if (user.person.names != null) this.user.person.names = user.person.names;
			this.user.role_id = user.role_id;
			this.user.enterprise_name = user.enterprise_name;
			this.user.enterprise_id = user.enterprise_id;
			this.user.campu_name = user.campu_name;
			this.user.campu_id = user.campu_id;
			this.user.job_position_name = user.job_position_name;
			this.user.job_position_id = user.job_position_id;
			this.requirements.campus = user.campus;
			this.requirements.job_positions = user.job_positions;
		},
		mtd_findPerson() {
			if (this.filter.document_number.length != 8) return window.message('info', 'DNI incorrecto');
			this.get({ url: `${this.url_api}/user/find/person?document_number=${this.filter.document_number}` })
				.then(res => {
					if (res.state == 'success') {
						if (res.person !== null) {
							this.mtd_setPerson(res.person);
						}
					} else if (res.state == 'error') {
						window.message('info', 'No se encuentra');
					}
				})
				.catch();
		},
		mtd_setPerson(person) {
			this.user.person.id = person.id;
			this.user.person.document_number = person.document_number;
			this.user.person.names = person.names;
			this.user.person.father_last_name = person.father_last_name;
			this.user.person.mother_last_name = person.mother_last_name;
		},
		mtd_changeFilterDocumentNumber() {
			this.user.person.document_number = this.filter.document_number;
		},
		mtd_changeUserPersonDocumentNumber() {
			this.filter.document_number = this.user.person.document_number;
		},
		mtd_changeDocumentType(document_type) {
			this.document_type_selected = document_type;
		},
		mtd_store: function () {
			this.user.enabled == true ? (this.user.enabled = 1) : (this.user.enabled = 0);

			this.post({ url: `${this.url_api}/user/store`, params: { user: this.user } })
				.then(res => {
					if (res.state == 'success') {
						this.$emit('success', { component: this.propInfo.component, res: { user: res.user }, action: 'store', modal_id: this.modal_id });
						window.message('success', 'Registro exitoso');
					}
				})
				.catch(errors => (this.errorsBackend = errors));
		},
		mtd_update: function () {
			this.update({ url: `${this.url_api}/user/${this.user.id}/update`, params: { user: this.user } })
				.then(res => {
					if (res.state == 'success') {
						this.$emit('success', { component: this.propInfo.component, res: { user: res.user }, action: 'update', modal_id: this.modal_id });
						window.message('success', 'Actualización exitosa');
					}
				})
				.catch(errors => (this.errorsBackend = errors));
		},
		$eval(expr) {
			return eval(expr);
		},
		mtd_close: function () {
			this.$emit('close');
		},
		mtd_send: function () {
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) {
					_this.$eval(_this.methods.action[this.propInfo.data.method]);
				}
			});
		},
		mtd_changeEnterprise: function () {
			this.user.job_position_id = null;
			this.user.campu_id = null;
			if (this.user.enterprise_id != null) {
				this.get({
					url: `${this.url_api}/users/${this.user.enterprise_id}/campus`,
				})
					.then(res => {
						this.requirements.job_positions = res.job_positions;
						this.requirements.campus = res.campus;
					})
					.catch();
			}
		},
		mtd_changeCampu: function () {
			this.user.job_position_id = null;
		},
	},
};
</script>
