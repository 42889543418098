<template>
	<div class="main-body p-0">
		<div class="inner-wrapper">
			<div class="inner-main">
				<page-header :propButtons="page.buttons" :propConfig="page.header" :propModel="dataModel" @action-create="mtd_showCreateResourceModal"></page-header>
				<div class="inner-main-body">
					<page-body
						:propHeader="dataTable.header"
						:propBody="dataTable.body"
						:propModel="dataModel"
						@action-next-pagination="mtd_nextPagination"
						@action-edit="mtd_showEditResourceModal"
						@action-remove="mtd_removeResource"
						@toggle-state="mtd_toggleStateResource">
						<template slot="content-filter">
							<component :is="filter_component" @emit-init-pagination="mtd_initPagination" :prop-execute="executeFilter" :page="dataTable.header.page"></component>
						</template>
					</page-body>
				</div>
			</div>
		</div>

		<modal v-for="modal in modals" :key="modal.id" class="bg-black-trans" :id="modal.id" :propConfig="modal.config" @action-close="mtd_closeModal(modal.id)">
			<template v-if="modal.id == 'main-form'">
				<component
					:is="modal.slot.component"
					slot="content-modal"
					:propInfo="modal.slot"
					:modal_id="modal.id"
					@onload="mtd_componentOnload(modal.id)"
					@success="mtd_componentSuccess"
					@error="mtd_componentError"
					@close="mtd_closeModal(modal.id)"></component>
			</template>
		</modal>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import MainForm from './components/MainForm';
import MainFilter from './components/MainFilter';

export default {
	components: {
		MainForm,
		MainFilter,
	},
	data() {
		return {
			filter_component: 'main-filter',
			executeFilter: 0,
			dataModel: {
				getter: 'users_index',
				prefixPermission: 'requiremets',
				alias: 'Usuarios',
				icon: 'fas fa-swatchbook',
			},
			page: {
				header: {
					menu: ['Usuarios'],
				},
				buttons: {
					create: true,
					import: false,
					export: false,
				},
			},
			dataTable: {
				header: {
					title: 'Usuarios',
					filter: false,
					page: 1,
					wordsearch: '',
				},
				body: {
					cols: [
						{
							name: 'role_name',
							alias: 'Rol',
							width: '',
						},
						{
							name: 'enterprise_campu',
							alias: 'Empresa / Sede',
							width: '',
						},
						{
							name: 'person_document_number',
							alias: 'Número Doc.',
							width: '',
						},
						{
							name: 'person_full_names',
							alias: 'Nombres',
							width: '',
						},
						{
							name: 'email',
							alias: 'Correo',
							width: '',
						},
						{
							name: 'enabled',
							alias: 'Estado',
							width: '',
							play: {
								0: "<span class='badge badge-danger'>Deshabilitado</span>",
								1: "<span class='badge badge-success'>Habilitado</span>",
							},
						},
					],
					buttons: {
						edit: true,
						remove: true,
						width: '',
					},
					myButtons: [
						{
							text: 'E',
							class: 'btn-primary btn-xs',
							tooltip: 'Cambiar estado',
							component: 'toggle-state',
							can: '',
						},
					],

					sonWindow: {
						component: null,
						row: null,
					},
				},
			},
			modals: [
				{
					id: 'main-form',
					slot: {
						component: null,
						data: {
							row: null,
							buttonSubmit: null,
							method: null,
						},
					},
					config: {
						title: null,
						id: 'main-form',
						idHash: '#main-form',
						size: null,
						scroll: true,
						position: '',
					},
				},
			],
		};
	},
	computed: {
		...mapGetters(['url_api']),
	},
	methods: {
		...mapActions(['get', 'post', 'destroy', 'execute_commit', 'update']),
		///////////////////// METODO POR DEFECTO /////////////////////
		mtd_componentOnload: function (modal_id) {
			window.$(`#${modal_id}`).modal('show');
		},

		mtd_componentSuccess: function ({ component, res, action, modal_id }) {
			switch (component) {
				case 'main-form':
					if (action == 'store') {
						this.execute_commit({ mutation: 'USERS_INDEX__ADD_RESOURCE', payload: { user: res.user } });
					}
					if (action == 'update') {
						this.execute_commit({ mutation: 'USERS_INDEX__UPDATE_RESOURCE', payload: { user: res.user } });
					}
					this.mtd_closeModal(modal_id);
					break;
				default:
					window.message('Warning', `No existe el modal ${component}`);
					break;
			}
		},
		mtd_componentError: function () {},
		mtd_closeModal: function (modal_id) {
			window.$(`#${modal_id}`).modal('hide');
			this.modals.find(el => el.id == modal_id).slot = {
				component: null,
				data: {
					row: null,
					buttonSubmit: null,
					method: null,
				},
			};
		},
		mtd_nextPagination: function (page) {
			this.dataTable.header.page = page;
		},
		mtd_initPagination: function () {
			this.dataTable.header.page = 0;
		},

		mtd_showCreateResourceModal: function () {
			const main_form = this.modals.find(el => el.id == 'main-form');

			main_form.slot.component = 'main-form';
			main_form.slot.data = {
				row: null,
				method: 'create',
				buttonSubmit: 'Registrar usuario',
			};
			main_form.config.scroll = true;
			main_form.config.size = 'modal-md';
			main_form.config.position = 'modal-right';
			main_form.config.title = "<span class='fa fa-plus'></span> Nuevo usuario";
		},
		mtd_showEditResourceModal: function (row) {
			const main_form = this.modals.find(el => el.id == 'main-form');

			main_form.slot.component = 'main-form';
			main_form.slot.data = {
				row: row,
				method: 'edit',
				buttonSubmit: 'Actualizar usuario',
			};
			main_form.config.scroll = true;
			main_form.config.size = 'modal-md';
			main_form.config.position = 'modal-right';
			main_form.config.title = "<span class='fa fa-edit'></span> Editando usuario";
		},
		mtd_removeResource: function (row) {
			this.destroy({ url: `${this.url_api}/user/${row.id}/remove` }).then(res => {
				if (res.state == 'success') {
					this.execute_commit({ mutation: 'USERS_INDEX__REMOVE_RESOURCE', payload: { user: row } });
					window.message('success', 'Eliminación exitosa');
				}
			});
		},
		mtd_toggleStateResource(row) {
			this.$swal
				.fire({
					title: `¿Esta seguro de ${row.enabled == 1 ? 'desactivar' : 'activar'} este usuario?`,
					text: `${row.name}`,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: `${row.enabled == 1 ? '#e74c3c' : '#28a745'}`,
					confirmButtonText: `Si, ${row.enabled == 1 ? 'desactivar' : 'activar'}`,
				})
				.then(result => {
					if (result.isConfirmed) {
						this.update({ url: `${this.url_api}/user/${row.id}/toggle_state` }).then(res => {
							if (res.state == 'success') {
								this.execute_commit({ mutation: 'USERS_INDEX__UPDATE_RESOURCE', payload: { user: res.user } });
							}
						});
					}
				});
		},
	},
	beforeDestroy() {
		this.execute_commit({ mutation: 'USERS_INDEX__CLEAR_ALL' });
	},
};
</script>
