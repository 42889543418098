<template>
	<div class="row">
		<div class="form-group col-md-4 col-lg-4">
			<label for="filter_text">Rol de usuario</label>
			<v-select v-model="role_id" :options="requirements.roles" :reduce="role => role.id" label="name" placeholder="Seleccionar" />
		</div>
		<div class="form-group col-md-4 col-lg-8">
			<label for="filter_text">Buscar</label>
			<div class="input-group">
				<input v-model="text" @keypress.enter="mtd_executeFilter" class="form-control form-control-sm" id="filter_text" type="text" />
				<button @click="mtd_executeFilter" class="btn btn-primary btn-sm">Filtrar</button>
			</div>
		</div>
	</div>
</template>
<script>
/** Vuex */
import { mapActions, mapGetters } from 'vuex';
export default {
	props: ['prop-execute', 'page'],
	data() {
		return {
			requirements: {
				roles: [],
			},

			role_id: null,
			text: null,
		};
	},
	created() {
		this.mtd_getRequirements();
		this.mtd_executeFilter();
	},
	computed: {
		...mapGetters(['url_api']),
	},
	watch: {
		propExecute: function (newValue) {
			if (newValue) this.mtd_executeFilter();
		},
		page: function (newValue) {
			if (newValue) this.mtd_executeFilter();
		},
	},
	methods: {
		...mapActions(['get', 'post', 'execute_commit']),
		mtd_getRequirements() {
			this.get({ url: `${this.url_api}/users/index/filters` })
				.then(res => {
					if (res.state == 'success') {
						this.requirements.roles = res.roles;
					}
				})
				.catch(err => console.log(err));
		},
		mtd_executeFilter(type = null) {
			let pagination = null;
			type == 'new' ? (pagination = 1) : (pagination = this.page);

			const params = {
				// url: `/api/mock/users/index?page=${pagination}&text=${this.text}`,
				url: `${this.url_api}/users/index?page=${pagination}&role_id=${this.role_id == null ? '' : this.role_id}&text=${this.text == null ? '' : this.text}`,
				mutation: 'USERS_INDEX__SET_ALL',
			};
			this.get(params);
		},
	},
};
</script>
